import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

type RouteParams = {
  code: string;
} & Record<string, string | undefined>;

const Upload: React.FC = () => {
  const { code } = useParams<RouteParams>();
  const [url, setUrl] = useState<string>('');

  const handleUpload = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await axios.post(`${process.env.REACT_APP_URL}/api/link/upload/${code}`, { url });
      alert('Link uploaded successfully!');
    } catch (error) {
      console.error('Error uploading link:', error);
      alert('Failed to upload the link.');
    }
  };

  return (
    <div>
      <h1>Upload a Link</h1>
      <form onSubmit={handleUpload}>
        <label>
          URL:
          <input
            type="text"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            placeholder="Enter the URL to upload"
            required
          />
        </label>
        <button type="submit">Upload Link</button>
      </form>
    </div>
  );
};

export default Upload;
