import React from 'react';
import { Link } from 'react-router-dom';

const Home: React.FC = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <h1><a href="/">ported.io</a></h1>
      <Link to="/send">
        <button>Send a Link</button>
      </Link>
      <Link to="/receive">
        <button>Receive a Link</button>
      </Link>
    </div>
  );
};

export default Home;
