import React, { useState } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import axios from 'axios';

const SendLink: React.FC = () => {
  const [url, setUrl] = useState('');
  const [qrCodeUrl, setQrCodeUrl] = useState('');

  const handleGenerate = async () => {
    const response = await axios.post(`${process.env.REACT_APP_URL}/api/link/send`, { url });
    const { code } = response.data;
    setQrCodeUrl(url); // Direct link QR code
  };

  return (
    <div>
      <h2>Send a Link</h2>
      <input
        type="text"
        placeholder="Paste your link here"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
      />
      <button onClick={handleGenerate}>Generate QR Code</button>
      {qrCodeUrl && (
        <div>
          <p>Scan this QR code:</p>
          <QRCodeCanvas value={qrCodeUrl} />
        </div>
      )}
    </div>
  );
};

export default SendLink;

