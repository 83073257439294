import React, { useState, useEffect } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import axios from 'axios';

const ReceiveLink: React.FC = () => {
  const [code, setCode] = useState('');
  const [receivedUrl, setReceivedUrl] = useState('');

  useEffect(() => {
    const fetchCode = async () => {
      const generatedCode = Math.random().toString(36).substring(2, 8);
      setCode(generatedCode);
      await axios.post(`${process.env.REACT_APP_URL}/api/link/send`, { url: '', code: generatedCode });
    };
    fetchCode();
  }, []);

  useEffect(() => {
    if (code) {
      const interval = setInterval(async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_URL}/api/link/receive/${code}`);
          setReceivedUrl(response.data.url);
          clearInterval(interval);
        } catch (error) {
          // Waiting for the link to be uploaded
        }
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [code]);

  const qrCodeValue = `${process.env.REACT_APP_URL}/upload/${code}`;

  return (
    <div>
      {!receivedUrl ? (
        <>
          <h2>Receive a Link</h2>
          <p>Scan this QR code with your phone:</p>
          <QRCodeCanvas value={qrCodeValue} />
        </>
      ) : (
        <div>
          <h2>Link Received!</h2>
          <a href={receivedUrl}>{receivedUrl}</a>
        </div>
      )}
    </div>
  );
};

export default ReceiveLink;
