import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Upload from './components/Upload';
import SendLink from './components/SendLink';
import ReceiveLink from './components/ReceiveLink';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/upload/:code" element={<Upload />} />
        <Route path="/send" element={<SendLink />} />
        <Route path="/receive" element={<ReceiveLink />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
